/* eslint-disable no-useless-escape */
import { lightFormat } from 'date-fns';
import React from 'react';
import { PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap';
import { MoHinhKinhDoanh } from '../views/QuanTri/CauHinhDonVi/constants';
import { TOKEN_CAUHINH_BANHANG } from './types';

// Thẩm định Email
export function VALIDATE_EMAIL(email) {
  return email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i);
}
// Loading
export function OPEN_LOADING_DATA_SERVER_API(msg, s) { // modal.style.display = "none";
  document.getElementById('Modal-show-loading-data-api-call') &&
    document.getElementById('Modal-show-loading-data-api-call').classList.add("Modal-show-loading-data-show-modal");
}
export function CLOSE_LOADING_DATA_SERVER_API(msg, s) { // modal.style.display = "none";
  document.getElementById('Modal-show-loading-data-api-call') &&
    document.getElementById('Modal-show-loading-data-api-call').classList.remove("Modal-show-loading-data-show-modal");
}
// Format Date
export function formatDate(value) { return value ? lightFormat(new Date(value), 'dd/MM/yyyy') : ''; }
export function formatTime(value) { return value ? lightFormat(new Date(value), 'HH:mm:ss') : ''; }
export function formatTime1(value) { return value ? lightFormat(new Date(value), 'HH:mm') : ''; }
export function formatDatetime(value) { return value ? lightFormat(new Date(value), 'dd/MM/yyyy HH:mm') : ''; }
export function formatISODate(value) { return value ? lightFormat(new Date(value), "yyyy-MM-dd'T'HH:mm:ss") : null; }
export function getSizeStr(size) {
  let value = size;
  if (value < 1000) return value + ' bytes';
  value = value / 1000;
  if (value < 1000) return value.toFixed(2) + ' KB';
  value = value / 1000;
  if (value < 1000) return value.toFixed(2) + ' MB';
  value = value / 1000;
  return value.toFixed(2) + ' GB';
}
// Format Currency
export function formatCurrency(value) {
  return new Intl.NumberFormat('vi').format((value !== undefined) ? value : 0);
}

export function limitText(text, numberTextToShow = text.length, symbolEndText = '') {
  let arrayText = text.split(' ');
  let symbol = numberTextToShow === text.length ? ' ' : symbolEndText;
  let result = arrayText.slice(0, numberTextToShow).join(' ') + symbol;
  return result;
}
export function limitTextToolTip(text, numberTextToShow = text.length, symbolEndText = '', titleTooltip = '', trigger = 'hover', id) {
  let arrayText = text.split(' ');
  let symbol = (numberTextToShow >= text.split(' ').length) ? ' ' : symbolEndText;
  let textResult = arrayText.slice(0, numberTextToShow).join(' ') + symbol;
  let result = <>
    <span id={id ? "PopoverClick" + id : "PopoverClick"} type="button">
      {textResult}
    </span>
    <UncontrolledPopover fade trigger={trigger} placement="bottom" target={id ? "PopoverClick" + id : "PopoverClick"}>
      <PopoverHeader>{titleTooltip}</PopoverHeader>
      <PopoverBody>{text}</PopoverBody>
    </UncontrolledPopover>
  </>
  return result;
}

export const getCurrentDay = () => {
  return new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate())
}
export const getCurrentDayString = () => {
  return lightFormat(getCurrentDay(), 'yyyy-MM-dd')
}
export const convertDateToDateTime = (date) => {
  let d = new Date(date);
  let now = new Date();
  return new Date(d.getFullYear(), d.getMonth(), d.getDate(), now.getHours(), now.getMinutes(), now.getSeconds())
}

export function removeVietnameseTones(str) {
  str = str.replace(/à|á|ạ|ả|ã|â|ầ|ấ|ậ|ẩ|ẫ|ă|ằ|ắ|ặ|ẳ|ẵ/g, "a");
  str = str.replace(/è|é|ẹ|ẻ|ẽ|ê|ề|ế|ệ|ể|ễ/g, "e");
  str = str.replace(/ì|í|ị|ỉ|ĩ/g, "i");
  str = str.replace(/ò|ó|ọ|ỏ|õ|ô|ồ|ố|ộ|ổ|ỗ|ơ|ờ|ớ|ợ|ở|ỡ/g, "o");
  str = str.replace(/ù|ú|ụ|ủ|ũ|ư|ừ|ứ|ự|ử|ữ/g, "u");
  str = str.replace(/ỳ|ý|ỵ|ỷ|ỹ/g, "y");
  str = str.replace(/đ/g, "d");
  str = str.replace(/À|Á|Ạ|Ả|Ã|Â|Ầ|Ấ|Ậ|Ẩ|Ẫ|Ă|Ằ|Ắ|Ặ|Ẳ|Ẵ/g, "A");
  str = str.replace(/È|É|Ẹ|Ẻ|Ẽ|Ê|Ề|Ế|Ệ|Ể|Ễ/g, "E");
  str = str.replace(/Ì|Í|Ị|Ỉ|Ĩ/g, "I");
  str = str.replace(/Ò|Ó|Ọ|Ỏ|Õ|Ô|Ồ|Ố|Ộ|Ổ|Ỗ|Ơ|Ờ|Ớ|Ợ|Ở|Ỡ/g, "O");
  str = str.replace(/Ù|Ú|Ụ|Ủ|Ũ|Ư|Ừ|Ứ|Ự|Ử|Ữ/g, "U");
  str = str.replace(/Ỳ|Ý|Ỵ|Ỷ|Ỹ/g, "Y");
  str = str.replace(/Đ/g, "D");
  // Some system encode vietnamese combining accent as individual utf-8 characters
  // Một vài bộ encode coi các dấu mũ, dấu chữ như một kí tự riêng biệt nên thêm hai dòng này
  str = str.replace(/\u0300|\u0301|\u0303|\u0309|\u0323/g, ""); // ̀ ́ ̃ ̉ ̣  huyền, sắc, ngã, hỏi, nặng
  str = str.replace(/\u02C6|\u0306|\u031B/g, ""); // ˆ ̆ ̛  Â, Ê, Ă, Ơ, Ư
  // Remove extra spaces
  // Bỏ các khoảng trắng liền nhau
  str = str.replace(/ + /g, " ");
  str = str.trim();
  // Remove punctuations
  // Bỏ dấu câu, kí tự đặc biệt
  str = str.replace(/!|@|%|\^|\*|\(|\)|\+|\=|\<|\>|\?|\/|,|\.|\:|\;|\'|\"|\&|\#|\[|\]|~|\$|_|`|-|{|}|\||\\/g, " ");
  return str;
}

export const dynamicsort = (property, order = 'asc') => {
  var sort_order = 1;
  if (order === "desc") {
    sort_order = -1;
  }
  return (a, b) => {
    // a should come before b in the sorted order
    if (a[property] < b[property]) {
      return -1 * sort_order;
      // a should come after b in the sorted order
    } else if (a[property] > b[property]) {
      return 1 * sort_order;
      // a and b are the same
    } else {
      return 0 * sort_order;
    }
  }
}

export const checkCauHinhDonViMoHinhKinhDoanh = () => {
  if (TOKEN_CAUHINH_BANHANG && TOKEN_CAUHINH_BANHANG.MoHinhKinhDoanh_Id === MoHinhKinhDoanh.CuaHang) {
    return true
  } else {
    return false
  }
}

export const sumGroupBy = (data1, data2, itemSum, itemCheck) => {
  const flattened = [data1, data2].reduce(
    (accumulator, currentValue) => accumulator.concat(currentValue),
    []
  ).reduce(function (res, obj) {
    if (!(obj[itemCheck] in res)) {
      res.__array.push(res[obj[itemCheck]] = obj);
    } else {
      res[obj[itemCheck]][itemSum] += obj[itemSum];
    }
    return res;
  }, { __array: [] });

  return flattened.__array
}

export const subGroupBy = (data1, data2, itemSub, itemCheck) => {
  const flattened = [data1, data2].reduce(
    (accumulator, currentValue) => accumulator.concat(currentValue),
    []
  ).reduce(function (res, obj) {
    if (!(obj[itemCheck] in res)) {
      res.__array.push(res[obj[itemCheck]] = obj);
    } else {
      res[obj[itemCheck]][itemSub] -= obj[itemSub];
    }
    return res;
  }, { __array: [] });

  return flattened.__array
}

export const settingData = (data) => {
  const arrData = [];
  data.forEach((curr, index, arr) => {
    if (!curr.ParentId) {
      const element = { ...curr }
      const children = arr.filter(x => x.ParentId === curr.Id);
      if (children.length !== 0) {
        const dataChild = getNestedChildren(data, curr.Id)
        element.children = dataChild;
      } else {
        element.children = [];
      }
      arrData.push(element);
    }
  })
  return arrData
}

function getNestedChildren(arr, idParent) {
  var children = [];
  arr.forEach((curr, i) => {
    if (curr.ParentId === idParent) {
      var grandChildren = getNestedChildren(arr, curr.Id)
      if (grandChildren.length) {
        curr.children = grandChildren;
      }
      children.push(curr);
    }
  })
  return children;
}
