import React from "react";
import { NhomBaoCao } from "../views/BaoCao/NhomBaoCao";
import { NhomLienHe } from "../views/QuanLyBanHang/DanhMuc/LienHe/NhomLienHe";

const TaiKhoan = React.lazy(() => import('../views/TaiKhoan/Index'));
const QuanTriHeThong = React.lazy(() => import('../views/QuanTri/TaiKhoan/SiteDonVi/Index'));
const DiemKinhDoanh = React.lazy(() => import('../views/QuanLyQRCode/DiemKinhDoanh/Page_Index'));

const Test = React.lazy(() => import('../views/QuanLyQRCode/Test/Page_Index'));
const LSGD = React.lazy(() => import('../views/QuanLyQRCode/LichSuGiaoDich/Page_Index'));
const SanPhamDichVu = React.lazy(() => import('../views/QuanLyBanHang/DanhMuc/SanPhamDichVu/Page_SanPhamDichVu'));
const NhomSanPhamDichVu = React.lazy(() => import('../views/QuanLyBanHang/DanhMuc/NhomSanPhamDichVu/Page_NhomSanPhamDichVu'));

const KhoanThu = React.lazy(() => import('../views/QLThuChi/Thu/Index_KhoanThu'));
const SoQuy = React.lazy(() => import('../views/QLThuChi/SoQuy/Index_SoQuy'));

const KhachHang = React.lazy(() => import('../views/QuanLyBanHang/DanhMuc/LienHe/Index'));
const DMKho = React.lazy(() => import('../views/QLKho/DMKho/Page_Index'));
const DMNhaCungCap = React.lazy(() => import('../views/QuanLyBanHang/DanhMuc/LienHe/Index'));

const PhieuNhap = React.lazy(() => import('../views/QLKho/PhieuNhap/Page_Index'));
// const PhieuNhap = React.lazy(() => import('../views/QLKho/PhieuMua'));

const ChuyenKho = React.lazy(() => import('../views/QLKho/ChuyenKho/Page_Index'));
const PhieuXuat = React.lazy(() => import('../views/QLKho/PhieuXuat/Page_Index'));
const PhieuXuatHuy = React.lazy(() => import('../views/QLKho/PhieuXuatHuy/Page_Index'));
const KiemKho = React.lazy(() => import('../views/QLKho/KiemKho/Page_Index'));
const KhuVucBan = React.lazy(() => import('../views/QuanLyBanHang/DanhMuc/KhuVucBan/Page_Index'));
const DMBan = React.lazy(() => import('../views/QuanLyBanHang/DanhMuc/Ban/Page_Index'));

const TinhGiaBan = React.lazy(() => import('../views/TinhGiaBan/Page_TinhGiaBan'));
// const TinhGiaBan = React.lazy(() => import('../views/ThietLapGia'));

const DonViTinh = React.lazy(() => import('../views/QuanLyBanHang/DanhMuc/DonViTinh/Page_Index'));
const YeuCauChuyenKho = React.lazy(() => import('../views/QLKho/ChuyenKho/Page_YeuCauChuyenKho'));
const CauHinhDonVi = React.lazy(() => import("../views/QuanTri/CauHinhDonVi"));
const KhuyenMai = React.lazy(() => import('../views/QuanLyBanHang/DanhMuc/KhuyenMai/Index'));
const NhanVien = React.lazy(() => import('../views/QuanLyBanHang/DanhMuc/NhanVien/Index'));
const ThietLapMauIn = React.lazy(() => import('../views/ThietLapMauIn/Page_ThietLapMauIn'));
const LoaiSanPham = React.lazy(() => import('../views/QuanLyBanHang/DanhMuc/LoaiSanPham/Page_Index'));
const VaiTroTaiKhoan = React.lazy(() => import('../views/QuanTri/VaiTro/Index'));
// Thẻ QR Code
const ThietBiDocQRCode = React.lazy(() => import('../views/QuanLyQRCode/ThietBiDocQRCode/Page_Index'));
const Phieu = React.lazy(() => import('../views/QuanLyQRCode/Phieu/Page_Index'));
const HanMucThe = React.lazy(() => import('../views/QuanLyQRCode/HanMucThe/Index'));
const BanTheNapTien = React.lazy(() => import('../views/QuanLyBanHang/BanTheNapTien/Index'));
const LoaiTheQRCode = React.lazy(() => import('../views/QuanLyQRCode/LoaiThe/Index'));
// Đơn hàng
// const TDH = React.lazy(() => import('../views/DonHang/Index'));
const TDH = React.lazy(() => import('../views/DonHangNew'));
// const DSDH = React.lazy(() => import('../views/DonHang/DanhSachDonHang/DetailDonHang/Page_DanhSachDonHang'));
const DSDH = React.lazy(() => import('../views/DonHangNew/DanhSachDonHang'));

const KhoanChi = React.lazy(() => import('../views/QLThuChi/Chi/Index_KhoanChi'));
// const DSTH = React.lazy(() => import('../views/DonHang/DanhSachTraHang/Page_DanhSachTraHang'));
const DSTH = React.lazy(() => import('../views/TraHang'));

const HopDong = React.lazy(() => import('../views/QuanLyBanHang/DanhMuc/HopDong'));

// Báo cáo
const BaoCao = React.lazy(() => import('../views/BaoCao/Index_BaoCao')); // Có sửa lại đường dẫn

// Tổng quan
const Dashboard = React.lazy(() => import('../views/Dashboard/BanHang/Index_Dashboard'));

// Quản lý chung cư
const DMDuAn = React.lazy(() => import('../views/QuanLyChungCu/views/DanhMuc/DuAn/index'));
const DMToaNha = React.lazy(() => import('../views/QuanLyChungCu/views/DanhMuc/ToaNha/index'));
const DMLoaiPhuongTien = React.lazy(() => import('../views/QuanLyChungCu/views/DanhMuc/LoaiPhuongTien/index'));
const DMLoaiDichVu = React.lazy(() => import('../views/QuanLyChungCu/views/DanhMuc/LoaiDichVu/index'));
const DMLoaiCanHo = React.lazy(() => import('../views/QuanLyChungCu/views/DanhMuc/LoaiCanHo/index'));
const DMThuongHieuPhuongTien = React.lazy(() => import('../views/QuanLyChungCu/views/DanhMuc/ThuongHieuPhuongTien/index'));

// vận hành tòa nhà
const QuanLyVanHanh = React.lazy(() => import('../views/VanHanhToaNha_Module/Views/BanQuanLy/IndexBanQuanLy'));
const DMQuyTrinh = React.lazy(() => import('../views/VanHanhToaNha_Module/Views/DMProcess/IndexDmProcess'));

const chucnang = [
  { path: '/cau-hinh-don-vi', exact: true, name: "Cấu hình đơn vị", component: CauHinhDonVi },
  { path: '/thong-tin-tai-khoan', exact: true, name: 'Thông tin tài khoản', component: TaiKhoan },
  { path: '/tai-khoan-phan-quyen', exact: true, name: 'Đơn vị - Tài khoản', component: QuanTriHeThong },
  { path: '/diem-kinh-doanh', exact: true, name: 'Điểm kinh doanh', component: DiemKinhDoanh },
  { path: '/test', exact: true, name: 'Test', component: Test },
  { path: '/lich-su-giao-dich', exact: true, name: 'LSGD', component: LSGD },
  { path: '/san-pham-dich-vu', exact: true, name: 'Sản phẩm dịch vụ', component: SanPhamDichVu },
  { path: '/nhom-san-pham-dich-vu', exact: true, name: 'Nhóm sản phẩm dịch vụ', component: NhomSanPhamDichVu },

  { path: '/khoanthu', exact: true, name: 'Khoản thu', component: KhoanThu },
  { path: '/so-quy', exact: true, name: 'Khoản thu', component: SoQuy },
  { path: '/tao-don-hang', exact: true, name: 'TDH', component: TDH },

  { path: '/hop-dong', exact: true, name: 'Danh sách hợp đồng', component: HopDong },

  { path: '/khoan-chi', exact: true, name: 'Khoản chi', component: KhoanChi },
  { path: '/don-hang', exact: true, name: 'Danh sách đơn hàng', component: DSDH },
  { path: '/khach-hang', exact: true, name: 'Liên hệ', component: KhachHang, params: { NhomLienHe_Ma: NhomLienHe.KH } },
  { path: '/danh-muc-kho', exact: true, name: 'Danh mục kho', component: DMKho },
  { path: '/phieu-nhap', exact: true, name: 'Phiếu nhập', component: PhieuNhap },
  { path: '/nha-cung-cap', exact: true, name: 'Nhà cung cấp', component: DMNhaCungCap, params: { NhomLienHe_Ma: NhomLienHe.NCC } },
  { path: '/chuyen-kho', exact: true, name: 'Chuyển kho', component: ChuyenKho },
  { path: '/xuat-hang', exact: true, name: 'Phiếu xuất', component: PhieuXuat },
  { path: '/xuat-huy', exact: true, name: 'Phiếu xuất hủy', component: PhieuXuatHuy },
  { path: '/ds-tra-hang', exact: true, name: 'Danh sách trả hàng', component: DSTH },
  { path: '/kiem-kho', exact: true, name: 'Kiểm kho', component: KiemKho },
  { path: '/khu-vuc-ban', exact: true, name: 'Khu vực bàn', component: KhuVucBan },
  { path: '/phong-ban', exact: true, name: 'Phòng/bàn', component: DMBan },
  { path: '/tinh-gia-ban', exact: true, name: 'Tính giá bán', component: TinhGiaBan },
  { path: '/don-vi-tinh', exact: true, name: 'Đơn vị tính', component: DonViTinh },
  { path: '/danh-muc-loai-san-pham', exact: true, name: 'Danh mục loại sản phẩm', component: LoaiSanPham },
  { path: '/yeu-cau-chuyen-kho', exact: true, name: 'Yêu cầu chuyển kho', component: YeuCauChuyenKho },

  { path: '/bao-cao-hang-hoa', exact: true, name: 'Báo cáo nhập xuất tồn', component: BaoCao, params: { NhomBaoCao: NhomBaoCao.HangHoa } },
  { path: '/bao-cao-ban-hang', exact: true, name: 'Báo cáo bán hàng', component: BaoCao, params: { NhomBaoCao: NhomBaoCao.BanHang } },
  { path: '/bao-cao-the', exact: true, name: 'Báo cáo thẻ', component: BaoCao, params: { NhomBaoCao: NhomBaoCao.The } },
  { path: '/bao-cao-KQKD', exact: true, name: 'Báo cáo kết quả kinh doanh', component: BaoCao, params: { NhomBaoCao: NhomBaoCao.KQKD } },
  { path: '/bao-cao-khuyen-mai', exact: true, name: 'Báo cáo khuyến mãi', component: BaoCao, params: { NhomBaoCao: NhomBaoCao.KhuyenMai } },
  { path: '/bao-cao-nhan-vien', exact: true, name: 'Báo cáo nhân viên', component: BaoCao, params: { NhomBaoCao: NhomBaoCao.NhanVien } },
  { path: '/bao-cao-khach-hang', exact: true, name: 'Báo cáo khách hàng', component: BaoCao, params: { NhomBaoCao: NhomBaoCao.KhachHang } },
  { path: '/bao-cao-ncc', exact: true, name: 'Báo cáo nhà cung cấp', component: BaoCao, params: { NhomBaoCao: NhomBaoCao.NhaCungCap } },
  { path: '/bao-cao-mua-hang', exact: true, name: 'Báo cáo mua hàng', component: BaoCao, params: { NhomBaoCao: NhomBaoCao.MuaHang } },

  { path: '/tong-quan', exact: true, name: 'Tổng quan', component: Dashboard },

  { path: '/khuyen-mai', exact: true, name: 'Chương trình khuyến mãi', component: KhuyenMai },

  { path: '/nhan-vien', exact: true, name: 'Nhân viên', component: NhanVien },
  { path: '/thiet-lap-mau-in', exact: true, name: 'Thiết lập mẫu in', component: ThietLapMauIn },

  { path: '/vai-tro-tai-khoan', exact: true, name: 'Vai trò tài khoản', component: VaiTroTaiKhoan },

  // Thẻ QR Code //
  { path: '/thiet-bi-dau-doc', exact: true, name: 'Thiết bị đọc QR Code', component: ThietBiDocQRCode },
  { path: '/quan-ly-the', exact: true, name: 'Quản lý phiếu', component: Phieu },
  { path: '/ban-the-nap-tien', exact: true, name: 'Bán thẻ nạp tiền', component: BanTheNapTien },
  { path: '/han-muc-the', exact: true, name: 'Hạn mức thẻ', component: HanMucThe },
  { path: '/loai-the', exact: true, name: 'Loại thẻ QR Code', component: LoaiTheQRCode },
  // { path: '/tong-quan-the', exact: true, name: 'Tổng quan', component: DashboardBanThe },

  //Quản lý chung cư
  { path: '/dm-du-an', exact: true, name: 'Danh mục dự án', component: DMDuAn },
  { path: '/dm-toa-nha', exact: true, name: 'Danh mục tòa nhà', component: DMToaNha },
  { path: '/dm-loai-phuong-tien', exact: true, name: 'Danh mục loại phương tiện', component: DMLoaiPhuongTien },
  { path: '/dm-loai-dich-vu', exact: true, name: 'Danh mục loại dịch vụ', component: DMLoaiDichVu },
  { path: '/dm-loai-can-ho', exact: true, name: 'Danh mục loại căn hộ', component: DMLoaiCanHo },
  { path: '/dm-thuong-hieu', exact: true, name: 'Danh mục thương hiệu', component: DMThuongHieuPhuongTien },

  // Quản lý vận hành
  { path: '/quan-ly-van-hanh', exact: true, name: 'Quản lý vận hành', component: QuanLyVanHanh },
  { path: '/danh-muc-quy-trinh', exact: true, name: 'Quản lý quy trình', component: DMQuyTrinh },

]
export default chucnang;
