import {combineReducers} from 'redux'
import todos from './todos'
import KKGDNtodos from './KKGDNtodos'
import loginTodos from './loginTodos'
import visibilityFilter from './visibilityFilter'
import {default as unitConfigs} from "../../views/QuanTri/CauHinhDonVi/reducer"

export default combineReducers({
  todos,
  KKGDNtodos,
  loginTodos,
  visibilityFilter,
  unitConfigs,
})
