//config host
//export const _API_URL = 'http://localhost:57875';
export const _API_URL = '/api';//'http://171.244.38.75:8989';
// export const API_URL
export const API_URL = _API_URL + '/odata';
// export const API_URL_MULTI
export const API_URL_MULTI = _API_URL + '/AddMulti';
// *************************************************************************
// Biến token client
export const ID_TOKEN_INFO = 'info';
export const ID_TOKEN_MENU = 'menu';
export const ID_TOKEN_PERMISSION = 'permission';
export const SETTINGS = 'settings';
// GET token info client
export const TOKEN_CLIENT_INFO = JSON.parse(localStorage.getItem(ID_TOKEN_INFO) || null);
export const PERMISSION = JSON.parse(localStorage.getItem(ID_TOKEN_PERMISSION) || null);

// GET cookie DiemKinhDoanh va Kho current
export const CURRENT_DKT_KHO = 'CurrentDKDKho';
export const TOKEN_DKT_KHO = JSON.parse(localStorage.getItem(CURRENT_DKT_KHO) || null);

// GET cookie CauHinhBanHang
export const CURRENT_CAUHINH_BANHANG = 'CurrentCauHinhBanHang';
export const TOKEN_CAUHINH_BANHANG = JSON.parse(localStorage.getItem(CURRENT_CAUHINH_BANHANG) || null);

// GET cookie CauHinhKhac
export const CURRENT_CAUHINH_KHAC = 'CurrentCauHinhKhac';
export const TOKEN_CAUHINH_KHAC = JSON.parse(localStorage.getItem(CURRENT_CAUHINH_KHAC) || null);
// TOKEN_DKT_KHO{ 
    // DiemKinhDoanhId
    // Kho_Id
    // IsKhoTong
// }

// TOKEN_CAUHINH_BANHANG{ Được tạo ở Cấu hình đơn vị
    // XuatHangTuDong
    // MoHinhKinhDoanh_Id
// }
