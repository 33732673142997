import { saveAs } from '../../actions/file-saver';
import * as Config from '../../actions/types';
import {
  OPEN_LOADING_DATA_SERVER_API,
  CLOSE_LOADING_DATA_SERVER_API
} from "../../actions/util";
import {
  CHECK_ERROR
} from "../../childcomp/alert/Alert";
import { logoutAccount } from '../../routes/permission';
export {
  _API_URL,
  API_URL,
  API_URL_MULTI
}
  from '../../actions/types';

const headers = new Headers();
headers.append('Accept', 'application/json');
headers.append('Content-Type', 'application/json');

/**
 * fetch trả về data kèm status
 * Thành công 200 - 300
 */
export function gdtFetch(url, options) {
  return fetch(url, options)
    .then(res =>
      res.json()
        .then(data => ({
          status: res.status,
          data
        })))
    .then(res => {
      if (res.status >= 200 && res.status < 300) {
        return Promise.resolve(res);
      } else {
        return Promise.reject(res);
      }
    });
}

//call API
export async function callApi(endpoint, method, body) {
  let url = `${Config.API_URL}/${endpoint}`;
  const result = await fetch(url, {
    method: method,
    mode: "cors",
    headers,
    body: body,
    credentials: 'same-origin'
  });
  return result.status === 200 && method === "GET" ? result.json() : result;
};

export async function callApiMulti(endpoint, method, body) {
  let url = `${Config.API_URL_MULTI}/${endpoint}`;
  const result = await fetch(url, {
    method: method,
    mode: "cors",
    headers,
    body: body,
    credentials: 'same-origin'
  });

  return result;
};

export async function _callApi(endpoint, method, body) {
  let url = `${Config._API_URL}/${endpoint}`;
  const result = await fetch(url, {
    method: method,
    mode: "cors",
    headers,
    body: body,
    credentials: 'same-origin'
  });

  return result;
};

//call API 2
export function callApi2NotLoading(endpoint, method = "GET", body, odata = true) {
  return new Promise((resolve, reject) => {
    var api = odata ? Config.API_URL : Config._API_URL;
    fetch(`${api}/${endpoint}`, { method: method, headers, mode: "cors", body: body, credentials: 'same-origin' })
      .then(async res => {
        if (res.status === 200 && (method === "GET" || method === "POST" || method === "PUT" || method === "DELETE")) {
          resolve(res.json());
        } else if (res.status === 201 && (method === "POST" || method === "PUT")) {
          resolve(res.json());
        } else if (res.status === 204 && (method === "PUT" || method === "PATCH" || method === "DELETE")) {
          resolve(res);
        } else if (res.status === 401) {
          logoutAccount()
        } else {
          reject(await res.json());
        }
      })
      .catch(err => { CHECK_ERROR("Không thể lấy dữ liệu !"); reject(err); })
      .finally();
  });
};

//call API
export function callApi2(endpoint, method = "GET", body, odata = true, enableSpinner = true) {
  return new Promise((resolve, reject) => {
    if (enableSpinner) OPEN_LOADING_DATA_SERVER_API();
    var api = odata ? Config.API_URL : Config._API_URL;
    fetch(`${api}/${endpoint}`, { method: method, headers, mode: "cors", body: body, credentials: 'same-origin' })
      .then(async res => {
        if (res.status === 200 && (method === "GET" || method === "POST" || method === "PUT" || method === "DELETE")) {
          resolve(res.json());
        } else if (res.status === 201 && (method === "POST" || method === "PUT")) {
          resolve(res.json());
        } else if (res.status === 204 && (method === "PUT" || method === "PATCH" || method === "DELETE")) {
          resolve(res);
        } else if (res.status === 401) {
          logoutAccount()
        } else {
          reject(await res.json());
        }
      })
      .catch(err => { CHECK_ERROR("Không thể lấy dữ liệu !"); reject(err); })
      .finally(() => CLOSE_LOADING_DATA_SERVER_API());
  });
};

//call API
export function callApiNotLoading(endpoint, method = "GET", body, odata = true) {
  return new Promise((resolve, reject) => {
    var api = odata ? Config.API_URL : Config._API_URL;
    fetch(`${api}/${endpoint}`, { method: method, headers, mode: "cors", body: body, credentials: 'same-origin' })
      .then(res => {
        if (res.status === 200 && (method === "GET" || method === "POST" || method === "PUT")) {
          resolve(res.json());
        } else if (res.status === 201 && (method === "POST" || method === "PUT")) {
          resolve(res.json());
        } else if (res.status === 204 && (method === "PUT" || method === "PATCH" || method === "DELETE")) {
          resolve(res);
        } else if (res.status === 401) {
          logoutAccount()
        } else {
          reject(res);
        }
      })
      .catch(err => { CHECK_ERROR("Không thể lấy dữ liệu !"); reject(err); });
  });
};

export function callApiFormData(endpoint, method, body) {
  return new Promise((resolve, reject) => {
    OPEN_LOADING_DATA_SERVER_API();
    fetch(`${Config._API_URL}/${endpoint}`, {
      method: 'POST', // *GET, POST, PUT, DELETE, etc.
      mode: 'cors',
      // no-cors, *cors, same-origin
      //cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
      body: body, // body data type must match "Content-Type" header
      credentials: 'same-origin'
    })
      .then(async res => {
        if (res.status === 401) {
          logoutAccount()
        } else {
          if (res.status === 400 || res.status === 500) {
            reject(await res.json());
          } else {
            resolve(res.json());
          }
        }
      })
      .catch(err => {
        CHECK_ERROR("Không thể lấy dữ liệu !");
        reject(err);
      })
      .finally(() => CLOSE_LOADING_DATA_SERVER_API());
  });
};
/**
 * Xử lý api tải tập tin về theo định dạng base64
 * @param {string} endpoint Địa chỉ đường dẫn api tải tập tin
 * @param {string} filename Tên tập tin cần lưu
 * @param {string} method Phương thức gọi api
 * @param {any} body Dữ liệu truyền lên nếu dùng phương thức POST
 * @param {boolean} download Mặc định tự động tải về, false nếu không muốn tự động tải
 * @returns {Promise<ArrayBuffer>} Trả về base64 chứa nội dung tập tin
 */
export function callApiDownload(endpoint, filename, method = 'GET', body, download = true) {
  const MIME_TYPE = {
    pdf: 'application/pdf',
    xls: 'application/vnd.ms-excel',
    xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  }
  return new Promise((resolve, reject) => {
    OPEN_LOADING_DATA_SERVER_API();
    fetch(`${Config._API_URL}/${endpoint}`, {
      method: method,
      mode: 'cors',
      body: body,
      credentials: 'same-origin'
    }).then(res => {
      if (!res.ok) throw new Error('Lỗi tải file: ' + res.status);
      else return res.arrayBuffer();
    }).then(buffer => {
      if (download) {
        const ext = filename.split('.').pop();
        saveAs(new Blob([buffer], { type: MIME_TYPE[ext] || 'application/octet-stream' }),
          filename || 'download.pdf')
      }
      resolve(buffer);
    })
      .catch(err => reject(err.message || err))
      .finally(() => CLOSE_LOADING_DATA_SERVER_API())
  })
}

export function callApiPublic(endpoint, method = 'GET', body) {
  return new Promise((resolve, reject) => {
    OPEN_LOADING_DATA_SERVER_API();
    fetch(`${Config._API_URL}/${endpoint}`, {
      method: method,
      mode: 'cors',
      body: body,
      headers: { 'Content-Type': 'application/json' },
      credentials: 'same-origin'
    })
      .then(res => resolve(res))
      .catch(err => reject(err))
      .finally(() => CLOSE_LOADING_DATA_SERVER_API())
  })
}

export function callApi2FakeVGCA(endpoint) {
  return new Promise((resolve, reject) => {
    OPEN_LOADING_DATA_SERVER_API();
    fetch(endpoint, { method: 'GET', headers, mode: "cors", body: null })
      .then(res => resolve(res))
      .catch(err => { CHECK_ERROR("Không thể lấy dữ liệu !"); reject(err); })
      .finally(() => CLOSE_LOADING_DATA_SERVER_API());
  });
};

export function callApiFormDataFakeVGCA(endpoint, formData) {
  return new Promise((resolve, reject) => {
    OPEN_LOADING_DATA_SERVER_API();
    fetch(`${endpoint}`, {
      method: 'POST',
      mode: 'cors',
      body: formData
    })
      .then(res => resolve(res.json()))
      .catch(err => {
        CHECK_ERROR("Không thể giả lập !");
        reject(err);
      })
      .finally(() => CLOSE_LOADING_DATA_SERVER_API());
  });
  //
};
