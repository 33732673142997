import * as types from "../constants/actionTypes";

var initialState = [];
const KKGDNtodos = (state = initialState, action) => {
  switch (action.type) {
    case types.LIST_EMPTY_TODO_DN:
      state = initialState;
      return state;
    case types.LIST_TODO_DN:
      console.log(action.data);
      return state.length ? [...state] : [...state, ...action.data];
    case types.ADD_TODO_DN:
      state.push(action.data);
      console.log(state);
      return [...state];
    case types.ADD_UPDATE_TODO_DN:
      console.log(action);
      const index = state.indexOf(action.data);
      state[index].TrangThaiSPKKG = true;
      state[index].GiaMoi = action.GiaMoi;
      return [...state];
    case types.LIST_HISTORY_TODO_DN:
      state = [];
      console.log(action.data);
      return [...state, ...action.data];
    case types.UPDATE_TODO_DN:
      state[state.indexOf(action.oldData)] = action.newData;
      return [...state];
    case types.DELETE_TODO_DN:
      state[state.indexOf(action.data)].TrangThaiSPKKG = false;
      return [...state];
    case types.DELETE_VIEW_TODO_DN:
      state.splice(state.indexOf(action.data), 1);
      return [...state];
    default:
      return state;
  }
};

export default KKGDNtodos;
