// Ke khai gia nha nuoc Acion
export const LIST_TODO = "LIST_TODO";
export const ADD_TODO = "ADD_TODO";
export const UPDATE_TODO = "UPDATE_TODO";
export const DELETE_TODO = "DELETE_TODO";

// Ke khai gia doang nghiep Actions
export const LIST_TODO_DN = "LIST_TODO_DN";
export const ADD_TODO_DN = "ADD_TODO_DN";
export const ADD_UPDATE_TODO_DN = "ADD_UPDATE_TODO_DN";
export const UPDATE_TODO_DN = "UPDATE_TODO_DN";
export const DELETE_TODO_DN = "DELETE_TODO_DN";
export const DELETE_VIEW_TODO_DN = "DELETE_VIEW_TODO_DN";
export const LIST_HISTORY_TODO_DN = "LIST_HISTORY_TODO_DN";
export const LIST_EMPTY_TODO_DN = "LIST_EMPTY_TODO_DN";

// Login Actions
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAILURE = "LOGIN_FAILURE";
