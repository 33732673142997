import * as types from "./constants"

const initialState = {
  isError: false,
  unitConfigs: [],
  businessModelValue: {},
  businessModelOptions: [],
  isLoading: false,
}

const loadUnitConfigsRequest = (state) => ({
  ...state,
  isLoading: true
})

const loadUnitConfigsSuccess = (state, action) => {
  return ({
    ...state,
    ...action.configValues,
    isLoading: false
  })
}

const loadUnitConfigsError = (state) => ({
  ...state,
  isError: true,
  isLoading: false,
})

const onInputChange = (state, action) => {
  const {target: {value}} = action.event
  return ({
    ...state,
    newConfigValue: value,
  })
}

const setActiveRow = (state, action) => {
  return ({
    ...state,
    activeRow: action.rowData
  })
}
const addConfigSuccess = (state, action) => ({
  ...state,
  values: [...state.values, action.newConfigValue]
})

const updateBusinessConfig = (state, action) => {

  const MoHinhKinhDoanh_Id = state
    .businessModelOptions
    .find(({Id}) => (Id === action.businessModelValue.MoHinhKinhDoanh_Id))

  return ({
    ...state,
    businessModelValue: action.businessModelValue,
    MoHinhKinhDoanh_Id,
  })
}

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case types.LOAD_UNIT_CONFIGS_REQUEST: {
      return loadUnitConfigsRequest(state, action)
    }
    case types.LOAD_UNIT_CONFIGS_SUCCESS: {
      return loadUnitConfigsSuccess(state, action)
    }
    case types.LOAD_UNIT_CONFIGS_FAILURE: {
      return loadUnitConfigsError(state)
    }
    case types.ON_INPUT_CHANGE: {
      return onInputChange(state, action)
    }
    case types.SET_ACTIVE_ROW: {
      return setActiveRow(state, action)
    }
    case types.ADD_CONFIG_SUCCESS: {
      return addConfigSuccess(state, action)
    }
    case types.UPDATE_BUSINESS_CONFIGS: {
      return updateBusinessConfig(state, action)
    }
    default:
      return state
  }
}

export default reducer
