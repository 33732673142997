import React from "react";

const Page_Index = React.lazy(() => import('../views/QuanTri/Page_Index'));
// const Page_DonVi = React.lazy(() => import('../views/QuanTri/Page_DonVi'));
// const Page_VaiTro = React.lazy(() => import('../views/QuanTri/Page_VaiTro'));
// const Page_Quyen = React.lazy(() => import('../views/QuanTri/Page_Quyen'));
// const CaiDatHeThong = React.lazy(() => import('../views/QuanTri/Page_CaiDatHeThong'));

const quantri = [
    { path: '/home-admin', exact: true, name: 'Home', component: Page_Index },
    // { path: '/don-vi', exact: true, name: 'Đơn vị - Tài khoản', component: Page_DonVi },
    // { path: '/vai-tro', exact: true, name: 'Vai trò', component: Page_VaiTro },
    // { path: '/quyen', exact: true, name: 'Quyền - Chức năng', component: Page_Quyen },
    // { path: '/cai-dat-chung', exact: true, name: 'Cài đặt chung', component: CaiDatHeThong },
];

export default quantri;