
export const NhomBaoCao = {
    The: { Ma: 'the', Ten: 'Thẻ' }, // Bị ẩn
    KQKD: { Ma: 'ket-qua-kinh-doanh', Ten: 'Kết quả kinh doanhs' }, // Bị ẩn
    BanHang:  { Ma: 'ban-hang', Ten: 'Bán hàng' },
    HangHoa:{ Ma: 'hang-hoa', Ten: 'Hàng hóa' },
    KhuyenMai:{ Ma: 'khuyen-mai', Ten: 'Khuyến mãi' },
    MuaHang:{ Ma: 'mua-hang', Ten: 'Mua hàng' },
    NhanVien:{ Ma: 'nhan-vien', Ten: 'Nhân viên' },
    KhachHang:{ Ma: 'khach-hang', Ten: 'Khách hàng' },
    NhaCungCap:{ Ma: 'nha-cung-cap', Ten: 'Nhà cung cấp' }
}