
import _quantri from './quantri';
import _chucnang from './chucnang';

const routes = [
  //************ QUẢN TRỊ HỆ THỐNG ****************
  ..._chucnang,
  ..._quantri
];

export default routes;