const typeCreator = (generic) => (type) => `${generic}_${type}`
const createLoadUnitTypes = typeCreator("LOAD_UNIT_CONFIGS")
const getOption = (option) => ({ label: option, value: option })

export const LOAD_UNIT_CONFIGS_REQUEST = createLoadUnitTypes("REQUEST")
export const LOAD_UNIT_CONFIGS_SUCCESS = createLoadUnitTypes("SUCCESS")
export const LOAD_UNIT_CONFIGS_FAILURE = createLoadUnitTypes("FAILURE")

export const ON_INPUT_CHANGE = "ON_INPUT_CHANGE"
export const SET_ACTIVE_ROW = "SET_ACTIVE_ROW"
export const ADD_CONFIG_SUCCESS = "ADD_CONFIG_SUCCESS"
export const UPDATE_BUSINESS_CONFIGS = "UPDATE_BUSINESS_CONFIGS"

export const costOfCapitalCalculationMethodOptions = [
  "FIFO",
  "BQGQ",
  "FILO"
].map(getOption)

export const MoHinhKinhDoanh = {
  Cafe: 'cafe',
  NhaPhanPhoi: 'dms',
  SieuThi: 'mart',
  CuaHang: 'store'
}
