import React, { Component } from 'react';
import { BrowserRouter, Route, Switch, Redirect } from 'react-router-dom';
import './scss/style.scss';
import Spinners from './childcomp/spinners/Spinners';
import * as permission from './routes/permission';
//
const loading = () => <Spinners />;
// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'));
// Pages
const Login = React.lazy(() => import('./views/Pages/Login/Login'));
//
export default class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <React.Suspense fallback={loading()}>
          {permission.checkLogin() ?
            <Switch>
              <Route path="/" name="Home" render={props => <DefaultLayout {...props} />} />
            </Switch>
            :
            <Switch>
              <Route exact path="/login" name="Login Page" render={props => <Login {...props} />} />
              <Redirect to={{ pathname: "/login" }} />
            </Switch>
          }
        </React.Suspense>
      </BrowserRouter>
    );
  }
}