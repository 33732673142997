import * as types from "../constants/actionTypes";
// var initialState = [
//   {
//     ten: "Tôm rào, tôm nước ngọt",
//     masanpham: "01",
//     mahanghoa: "01.0012",
//     dacdiem: "Loại 40-45 con/kg",
//     donvitinh: "đ/kg",
//     loaigia: 1,
//     giakytruoc: "1000",
//     giakynay: "2000",
//     muc: "1000",
//     tyle: "2",
//     nguonthongtin: "",
//     ghichu: "Ghi rõ tên con tôm"
//   },
//   {
//     ten: "Du lịch trọn gói trong nước",
//     masanpham: "09",
//     mahanghoa: "09.0001",
//     dacdiem: "Cho 2 người chuyến 2 ngày 1 đêm (từ đâu, đến đâu...)",
//     donvitinh: "đ/người/chuyến",
//     loaigia: 2,
//     giakytruoc: "1000000",
//     giakynay: "2000000",
//     muc: "1000000",
//     tyle: "2",
//     nguonthongtin: "",
//     ghichu: ""
//   },
//   {
//     ten: "X-quang số hóa 1 phim",
//     masanpham: "06",
//     mahanghoa: "06.0004",
//     dacdiem: "Chữa bệnh của Nhà nước",
//     donvitinh: "đ/lượt",
//     loaigia: 2,
//     giakytruoc: "1500000",
//     giakynay: "2500000",
//     muc: "1000000",
//     tyle: "2",
//     nguonthongtin: "",
//     ghichu: ""
//   },
//   {
//     ten: "Giá cước taxi",
//     masanpham: "07",
//     mahanghoa: "07.0005",
//     dacdiem: "Lấy giá 10km đầu, loại xe 4 chỗ",
//     donvitinh: "đ/người/chuyến",
//     loaigia: 2,
//     giakytruoc: "11000",
//     giakynay: "12000",
//     muc: "1000",
//     tyle: "0.11",
//     nguonthongtin: "",
//     ghichu: ""
//   },
//   {
//     ten: "Vàng 99,99%",
//     masanpham: "10",
//     mahanghoa: "10.0001",
//     dacdiem: "Kiểu nhẫn tròn 1 chỉ",
//     donvitinh: "1000đ/chỉ",
//     loaigia: 2,
//     giakytruoc: "3000000",
//     giakynay: "3200000",
//     muc: "2000000",
//     tyle: "0.2",
//     nguonthongtin: "",
//     ghichu: "Vàng nhẫn tròn thị trường tự do"
//   }
// ];
var initialState = [];
const todos = (state = initialState, action) => {
  switch (action.type) {
    case types.LIST_TODO:
      console.log(action);
      return state.length ? [...state] : [...state, ...action.data];
    case types.ADD_TODO:
      state.push(action.data);
      console.log(state);
      return [...state];
    case types.UPDATE_TODO:
      state[state.indexOf(action.oldData)] = action.newData;
      return [...state];
    case types.DELETE_TODO:
      state.splice(state.indexOf(action.data), 1);
      return [...state];
    case "TOGGLE_TODO":
      return state.map(todo =>
        todo.id === action.id ? { ...todo, completed: !todo.completed } : todo
      );
    default:
      return state;
  }
};

export default todos;
