import _routes from './routes';
import { callApiPublic, callApiNotLoading, callApi2 } from '../service/api';
import { CHECK_ERROR } from '../childcomp/alert/Alert';
import {
  PERMISSION, ID_TOKEN_PERMISSION, SETTINGS,
  ID_TOKEN_INFO, ID_TOKEN_MENU, TOKEN_CLIENT_INFO, CURRENT_DKT_KHO, CURRENT_CAUHINH_BANHANG, CURRENT_CAUHINH_KHAC
} from '../actions/types';
import { OPEN_LOADING_DATA_SERVER_API, CLOSE_LOADING_DATA_SERVER_API } from '../actions/util';

const checkCondition = (route, permission) => route.path === permission.url;
const checkHasPermission = (route, permissions) => permissions.some(permission => checkCondition(route, permission));

//get setting by name
export function getSettingByName(name) {
  if (localStorage.getItem(SETTINGS) !== null) {
    try {
      const list = JSON.parse(localStorage.getItem(SETTINGS));
      return list.find(e => e.SETTING_KEY === name);
    } catch (err) {
      console.log(err);
    }
  }
};

//get setting by name
export function getSettingValueByName(name) {
  const setting = getSettingByName(name);
  if (setting) {
    if (setting.SETTING_TYPE === 'boolean') {
      return setting.SETTING_VALUE === 'true';
    }
    if (setting.SETTING_TYPE === 'numeric') {
      return Number(setting.SETTING_VALUE);
    }
    return setting.SETTING_VALUE;
  }
  return null;
};

//get settings
export function getListSetting() {
  if (!localStorage.getItem(SETTINGS)) {
    callApiNotLoading('Settings?$select=SETTING_KEY,SETTING_VALUE,SETTING_TYPE')
      .then(res => res.value && localStorage.setItem(SETTINGS, JSON.stringify(res.value)))
      .catch(err => console.log(err));
  }
};

//check permission
export function getPermissions() {
  let permissions = [];
  if (localStorage.getItem(ID_TOKEN_PERMISSION)) {
    try {
      // lấy danh sách quyền được cấp phép
      return JSON.parse(localStorage.getItem(ID_TOKEN_PERMISSION));
    } catch (err) {
      console.log(err);
    }
  } else {
    if (localStorage.getItem(ID_TOKEN_INFO) !== null
      && localStorage.getItem(ID_TOKEN_MENU) === null
      && localStorage.getItem(ID_TOKEN_PERMISSION) === null
    ) {
      callApiNotLoading('Login/GetMenu', 'GET', null, false)
        .then(res => {
          if (res.menu && res.danh_sach_chuc_nang) {
            localStorage.setItem(ID_TOKEN_MENU, JSON.stringify(res.menu));
            localStorage.setItem(ID_TOKEN_PERMISSION, JSON.stringify(res.danh_sach_chuc_nang));
            window.location.replace(TOKEN_CLIENT_INFO.TrangChu ? TOKEN_CLIENT_INFO.TrangChu : '/');
          }
        })
        .catch(err => console.log(err));
    }
  }
  return permissions;
};

//check permission
export function checkPermission(action) {
  if (!action) return false;
  var chuc_nang = PERMISSION.filter(item => item.url === window.location.pathname);
  //console.log(chuc_nang);
  if (chuc_nang && chuc_nang[0] && chuc_nang[0][action]) {
    return chuc_nang[0][action];
  } else {
    return false;
  }
};

export function checkPermissionUrl(action, url) {
  if (!url) return false;
  if (!action) return false;
  var chuc_nang = PERMISSION.filter(item => item.url === url);
  //console.log(chuc_nang);
  if (chuc_nang && chuc_nang[0] && chuc_nang[0][action]) {
    return chuc_nang[0][action];
  } else {
    return false;
  }
};

//check routes permission
export function checkPermissionsRoutes() {
  let routes = [];
  let permissions = getPermissions();
  // Lọc những route có trong danh sách quyền
  routes = _routes.filter(route => checkHasPermission(route, permissions));
  return [...routes];
};

//check permission
export function getRouteDanhMuc(url) {
  const route = _routes.filter(route => route.odata && route.odata.url && route.odata.url === url);
  if (route && route.length > 0) {
    const danhmuc = route[0];
    // lấy danh sách quyền được cấp phép
    if (checkHasPermission(danhmuc, getPermissions())) {
      return danhmuc;
    }
  }
  return null;
};

//check menus
export function checkMenus() {
  if (localStorage.getItem(ID_TOKEN_MENU)) {
    try {
      return JSON.parse(localStorage.getItem(ID_TOKEN_MENU));
    } catch (err) {
      console.log(err);
    }
  }
  return [];
};

function clearTokens() {
  localStorage.removeItem(ID_TOKEN_INFO);
  localStorage.removeItem(ID_TOKEN_MENU);
  localStorage.removeItem(ID_TOKEN_PERMISSION);
  localStorage.removeItem(SETTINGS);

  localStorage.removeItem(CURRENT_DKT_KHO)
  localStorage.removeItem(CURRENT_CAUHINH_BANHANG)
  localStorage.removeItem(CURRENT_CAUHINH_KHAC)
  TOKEN_CLIENT_INFO && localStorage.removeItem(`${TOKEN_CLIENT_INFO.MaTaiKhoan}`)
  localStorage.removeItem('BangGia')
};

//check logins
export function checkLogin() {
  if (!TOKEN_CLIENT_INFO) {
    clearTokens();
    return false;
  }
  // try {
  //     const { iss, aud } = jwt_decode(TOKEN_CLIENT);
  //     if (iss === 'gdt_quanlygia' && aud === 'gdt_quanlygia') {
  //         if (localStorage.getItem(ID_TOKEN_INFO) != null
  //             // && localStorage.getItem(ID_TOKEN_MENU) != null
  //             // && localStorage.getItem(ID_TOKEN_PERMISSION) !== null
  //         ) {
  //             return true;
  //         }
  //     }
  // } catch {
  //     clearTokens();
  // }
  // return false;
  return true;
};

export async function loginAccount(username, password) {
  let response = await callApiPublic(`Login/Authenticate`, 'POST', JSON.stringify({
    Username: username,
    Password: password
  })).then(res => res.json()).catch(err => CHECK_ERROR(err));
  try {
    if (response.Message !== undefined) {
      CHECK_ERROR(response.Message);
      return false;
    }
    !!response.TaiKhoan && localStorage.setItem(ID_TOKEN_INFO, JSON.stringify(response.TaiKhoan));

    window.location.replace(response.TaiKhoan.TrangChu ? response.TaiKhoan.TrangChu : '/');
  } catch (err) {
    CHECK_ERROR("Không thể đăng nhập !");
    return false;
  }
}

export function logoutAccount() {
  OPEN_LOADING_DATA_SERVER_API();
  setTimeout(function () {
    callApi2('Login/LogOff', 'POST', null, false)
      .then(() => {
        clearTokens();
        window.location.reload(true);
      })
      .catch(err => {
        clearTokens();
        window.location.reload(true);
      })
      .finally(() => CLOSE_LOADING_DATA_SERVER_API());
  }, 500);
}
