import * as types from '../constants/actionTypes';
var initialState = []
const loginTodos = (state = initialState, action) => {
  switch (action.type) {
    case types.LOGIN_REQUEST:
      state.push(action.data);
      return [...state]
    case types.LOGIN_SUCCESS:
      state[state.indexOf(action.oldData)] = action.newData;
      return [...state]
    case types.LOGIN_FAILURE:
      state.splice(state.indexOf(action.data), 1);
      return [...state]
    default:
      return state
  }
}

export default loginTodos