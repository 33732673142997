import './style/modal-show-loading.css';
import styled from 'styled-components';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import Spinners from './childcomp/spinners/Spinners';
import store from './redux/store';
import { Provider } from 'react-redux';

const ModalContent = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    opacity: 0.5;
    padding: 1rem 1.5rem;
    width: auto;
    border-radius: 0.5rem;
`;

ReactDOM.render(
    <>
         <Provider store={store}>
        <App />
         </Provider>
        <div id="Modal-show-loading-data-api-call" className="Modal-show-loading-data-modal">
            <ModalContent>
                <Spinners />
            </ModalContent>
        </div>
    </>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
