import * as types from "../constants/actionTypes";
import { callApi, _callApi } from "../../service/api/index";

export const listTodo_Async = data => ({
  type: types.LIST_TODO,
  data
});

export const listTodo = () => {
  return dispatch => {
    return callApi("ThongTinVeGias?$top=1&$orderby=TGTao desc", "GET", null)
      .then(res => res.value)
      .then(res =>
        callApi(
          `SanPhamTTVGs?$expand=HangHoaDichVu/DonViTinh,HangHoaDichVu/SanPham,ThongTinVeGia,LoaiHangHoaDichVu,LoaiGia&$filter=ThongTinVeGia/ID eq '${
          res[0].ID
          }'`
        )
      )
      .then(res => res.json())
      .then(res => {
        dispatch(listTodo_Async(res.value));
      });
  };
};

export const addTodo = data => ({
  type: types.ADD_TODO,
  data
});

export const addAllTodo = data => ({
  type: types.ADD_TODO,
  data
});

export const updateTodo = (newData, oldData) => ({
  type: types.UPDATE_TODO,
  newData,
  oldData
});

export const deleteTodo = data => ({
  type: types.DELETE_TODO,
  data
});

export const listEmptyTodoDN = () => ({
  type: types.LIST_EMPTY_TODO_DN
});

export const listTodoDN_Async = data => ({
  type: types.LIST_TODO_DN,
  data
});

export const listTodoDN = () => {
  return dispatch => {
    return _callApi("KeKhai/KeKhaiRecent", "GET", null)
      .then(res =>
        res.status === 204 ? new Promise(resolve => resolve(null)) : res.json()
      )
      .then(res =>
        callApi(
          `SanPhamKKGs?$expand=HangHoaDichVu/DonViTinh,KeKhaiGia,LoaiHangHoaDichVu&$filter=KeKhaiGia/ID eq '${res}'`
        )
      )
      .then(res => res.json())
      .then(res => {
        res.value.forEach(element => {
          element.GiaCu = element.GiaMoi;
          element.GiaMoi = "";
        });
        dispatch(listTodoDN_Async(res.value));
      })
      .catch(err => console.log(err.message));
  };
};

export const listHistoryTodoDN_Async = data => ({
  type: types.LIST_HISTORY_TODO_DN,
  data
});

export const listHistoryTodoDN = id => {
  return dispatch => {
    const url = `SanPhamKKGs?$expand=HangHoaDichVu,KeKhaiGia&$filter=KeKhaiGia/ID eq '${id}'`;
    return callApi(url, "GET", null)
      .then(res => dispatch(listHistoryTodoDN_Async(res.value)))
      .catch(err => console.log(err.message));
  };
};

export const addTodoDN = data => ({
  type: types.ADD_TODO_DN,
  data
});

export const addUpdateTodoDN = (data, GiaMoi) => ({
  type: types.ADD_UPDATE_TODO_DN,
  data,
  GiaMoi
});

// export const addAllTodoDN_Async = data => ({
//   type: types.ADD_ALL_TODO_DN,
//   data
// })

export const addAllTodoDN = data => {
  return (dispatch, getState) => {
    // dispatch(addAllTodoDN_Async(data));
    let state = getState();
    console.log(data);
    console.log(state.KKGDNtodos);
    let SanPhamKKG = state.KKGDNtodos.filter(item => {
      delete item.HangHoaDichVu;
      delete item.KeKhaiGia;
      delete item.tableData;
      return item;
    });
    console.log(SanPhamKKG);
    // callApiPostMulti("SanPhamKKGs", JSON.stringify(SanPhamKKG))
    //   .then(res => res)
    //   .catch(err => console.log(err.message));
    // callApi("KeKhaiGias", "POST", JSON.stringify(data))
    //   .then(res => res)
    //   .catch(err => console.log(err.message));
  };
};

export const updateTodoDN = (newData, oldData) => ({
  type: types.UPDATE_TODO_DN,
  newData,
  oldData
});

export const deleteTodoDN = data => ({
  type: types.DELETE_TODO_DN,
  data
});

export const deleteViewTodoDN = data => ({
  type: types.DELETE_VIEW_TODO_DN,
  data
});

export const setVisibilityFilter = filter => ({
  type: "SET_VISIBILITY_FILTER",
  filter
});

export const toggleTodo = id => ({
  type: "TOGGLE_TODO",
  id
});

export const VisibilityFilters = {
  SHOW_ALL: "SHOW_ALL",
  SHOW_COMPLETED: "SHOW_COMPLETED",
  SHOW_ACTIVE: "SHOW_ACTIVE"
};

// LOGIN REQUEST ACTION
export const requestLogin_Async = creds => ({
  type: types.LOGIN_REQUEST,
  isFetching: true,
  isAuthenticated: false,
  creds
});

export const requestLogin = creds => {
  return dispatch => {
    dispatch(requestLogin(creds));
    // return _callApi("Login", "POST", JSON.stringify(creds))
    //   .then(res => res.json())
    //   .catch(err => {
    //     console.log(err);
    //   });

    //   return _callApi('Login', 'POST', JSON.stringify(creds))
    //     .then(res => {
    //       if (res.status === 401) {
    //         CLOSE_LOADING_DATA_SERVER_API();
    //         CHECK_ERROR_CLIENT("Tài khoản hoặc mật khẩu không chính xác");
    //         return;
    //       }
    //       return res.json().then(res => {
    //         localStorage.setItem(ID_TOKEN_CLIENT, res);
    //         CLOSE_LOADING_DATA_SERVER_API();
    //         window.location.replace('/');
    //       });
    //     })
    //     .catch(err => {
    //       CLOSE_LOADING_DATA_SERVER_API();
    //       CHECK_API_ERROR_CLIENT(err);
    //     });
  };
};

// LOGIN SUCCESS ACTION
export const receiveLogin = id_token => ({
  type: types.LOGIN_SUCCESS,
  isFetching: false,
  isAuthenticated: true,
  id_token
});
// LOGIN FAILURE ACTION
export const loginError = message => ({
  type: types.LOGIN_FAILURE,
  isFetching: false,
  isAuthenticated: false,
  message
});
